import React, { FC } from "react";
import { Props as FlowingCommentProps } from "components/front/order/FlowingComment";
import dynamic from "next/dynamic";
import styled from "styled-components";
import Logo from "../header/Logo";

const FlowingComment = dynamic<FlowingCommentProps>(() => import("components/front/order/FlowingComment"), { ssr: false });
const TippingNotification = dynamic<Record<string, never>>(() => import("components/front/order/TippingNotification"), { ssr: false });
const MemberLink = dynamic(() => import("components/front/header/MemberLink"), { ssr: false });

// TODO: headerディレクトリへ移動予定
export type Props = {
  escapeDerailed?: boolean;
  positionFixed?: boolean;
};
export const Header: FC<Props> = ({ escapeDerailed, positionFixed = true }) => (
  <Root $positionFixed={positionFixed}>
    <LinkArea>
      <Logo data-cy="logo" />
      <MemberLink escapeDerailed={escapeDerailed} />
    </LinkArea>
    <BoardArea>
      <FlowingComment />
      <TippingNotification />
    </BoardArea>
  </Root>
);

const Root = styled.header<{ $positionFixed: boolean }>`
  position: ${({ $positionFixed }) => ($positionFixed ? "fixed" : "static")};
  width: 100%;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 99;
  @media screen and (max-width: 960px) {
    height: 70px;
    background-image: url(/img/header_bg--sp.png);
    padding: 6px 10px 7px;
  }
  @media screen and (min-width: 961px) {
    height: 108px;
    background-image: url(/img/header_bg--pc.png);
    padding: 14px 40px 14px;
  }
`;
const LinkArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 960px) {
    height: 28px;
    margin-bottom: 9px;
  }
  @media screen and (min-width: 961px) {
    height: 48px;
    margin-bottom: 14px;
  }
`;

const BoardArea = styled.div`
  position: relative;
`;
