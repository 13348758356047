import React, { FC, HTMLAttributes } from "react";
import Link from "next/link";
import styled, { keyframes } from "styled-components";

type Props = HTMLAttributes<HTMLAnchorElement>
const Logo: FC<Props> = ({ ...props }) => {
  return (
    <Link href="/">
      <Anchor {...props}>
        <LogoImage src="/img/header_logo.png" width="194" height="38" alt=""/>
      </Anchor>
    </Link>
  );
};
export default Logo;

const LogoFlash = keyframes`
  0% {
    opacity: 0.5;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.7;
  }
  6% {
    opacity: 1;
  }
  8% {
    opacity: 0.6;
  }
  10% {
    opacity: 1;
  }
  12% {
    opacity: 1;
  }
  14% {
    opacity: 0.5;
  }
  16% {
    opacity: 1;
  }
  18% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;
const Anchor = styled.a`
  cursor: pointer;

  &:hover {
    @media screen and (min-width: 961px) {
      transition: 0.4s;
      animation: ${LogoFlash} 2.8s step-start infinite;
    }
  }
`;
const LogoImage = styled.img`
  @media screen and (max-width: 960px) {
    width: auto;
    height: 24px;
  }
`;
